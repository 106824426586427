import React from 'react';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import Terms from '../components/Terms';

export default () => {
    return (
        <>
            <Navigation></Navigation>
            <Terms></Terms>
            <Footer></Footer>
        </>
    );
}